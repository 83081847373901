// extracted by mini-css-extract-plugin
export const section = "hero-feedback-module--section--3MVfO";
export const heroImage = "hero-feedback-module--hero-image--34s4X";
export const content = "hero-feedback-module--content--1ElM0";
export const tab = "hero-feedback-module--tab--2MvyS";
export const ctaContainer = "hero-feedback-module--cta-container--38cHg";
export const cta = "hero-feedback-module--cta--3L9vT";
export const formElement = "hero-feedback-module--form-element--2cPR6";
export const starContainer = "hero-feedback-module--star-container--hsWJC";
export const ratingContainer = "hero-feedback-module--rating-container--3HEDC";
export const rangeStart = "hero-feedback-module--range-start--1HBH5";
export const rangeEnd = "hero-feedback-module--range-end--mil7h";