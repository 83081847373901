import React, { useEffect, useState } from "react";

import AutoCompleteInput from "../components/autocomplete-input";
import StarRating from "../components/star-rating";

import * as style from "./hero-feedback.module.css";

function FormInput({
  type,
  label,
  name,
  value,
  onChange,
  withRating,
  options,
  from,
  to,
  ...rest
}) {
  const [starRating, setStarRating] = useState(0);

  const id = `_ff_${name}`;

  const Input = type === "textarea" ? "textarea" : "input";

  const input =
    type === "select" ? (
      <select
        {...{ id, type, name, [onChange ? "value" : "defaultValue"]: value }}
      >
        {(options || []).map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <Input
        {...rest}
        {...{ id, type, name, [onChange ? "value" : "defaultValue"]: value }}
        onChange={
          onChange
            ? (event) => {
                onChange(event.target.value);
              }
            : null
        }
      />
    );

  return (
    <div className={style.formElement}>
      <label htmlFor={id}>{label}</label>
      {withRating && (
        <div>
          <div className={style.ratingContainer}>
            <div>
              {from && <span className={style.rangeStart}>{from}</span>}
              {to && <span className={style.rangeEnd}>{to}</span>}
            </div>
            <StarRating
              value={starRating}
              onChange={setStarRating}
              containerClassName={style.starContainer}
            />
            <input
              type="hidden"
              defaultValue={starRating}
              name={`${name}_numeric`}
            />
          </div>
        </div>
      )}
      {input}
    </div>
  );
}

async function fetchCompanies() {
  const response = await fetch("/api/v1/companies");
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}

export default function HeroFeedbackSection({ data }) {
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    (async () => {
      const result = await fetchCompanies();
      setCompanies(result);
    })().catch(console.error);
  }, []);

  return (
    <section className={style.section}>
      <img src={data.hero.publicURL} alt="" className={style.heroImage} />

      <div className={style.content}>
        <h2>
          Let other diverse professionals know what your workplace feels like...
        </h2>

        <a href="#form" onClick={() => {}}></a>

        <p>
          Your answers are absolutely anonymous. We never associate any personal
          information, your IP address or any other information that could help
          identify you with your answers.
        </p>

        <form
          id="form"
          action="/api/v1/feedback"
          method="POST"
          encType="multipart/form-data"
        >
          <div className={style.formElement}>
            <label htmlFor="_ff_company_name">Company</label>
            <AutoCompleteInput
              id="_ff_company_name"
              name="company_name"
              required
              value={companyName}
              onChange={setCompanyName}
              getSuggestions={(value) =>
                companies
                  .filter((company) =>
                    company.name.toLowerCase().startsWith(value.toLowerCase())
                  )
                  .map((company) => company.name)
              }
              options={{ highlightPattern: true }}
            />
          </div>

          {/* <FormInput
            type="text"
            name="position"
            label="Your position"
            placeholder="e.g. IT Project Manager"
          /> */}
          <FormInput
            type="select"
            name="minority"
            label="You identify as / You are"
            options={[
              { label: "International Professional", value: "international" },
              { label: "Woman", value: "woman" },
              { label: "Parent", value: "parent" },
              { label: "LGBTQ+", value: "lgbtq+" },
              { label: "Neurodiverse Person", value: "neurodiverse" },
              { label: "Person with disability", value: "disabled" },
              {
                label: "Person with chronical illness (physical or mental)",
                value: "chronical-illness",
              },
              { label: "Other", value: "other" },
            ]}
          />

          <FormInput
            type="textarea"
            name="overall"
            label="How satisfied are/were you with the company's diversity and inclusion?"
            withRating
          />

          {/* <FormInput
            type="textarea"
            name="overall"
            label="How diverse is your company really? Diverse colleagues? Diverse management?"
            withRating
          />
          <FormInput
            type="textarea"
            name="colleagues"
            label="Do your colleagues or superiors contribute to your feeling of belonging? How?"
            withRating
          />
          <FormInput
            type="textarea"
            name="microaggressions"
            label="How often do you experince microagressions?"
            from="Never"
            to="Often"
            withRating
          />
          <FormInput
            type="select"
            options={[
              { label: "German", value: "de" },
              { label: "English", value: "en" },
              { label: "Other", value: "other" },
            ]}
            name="spoken-language"
            label="Language mostly spoken?"
          />
          <FormInput
            type="textarea"
            name="training"
            label="Diversity & inclusion training?"
            withRating
          /> */}
          <button type="submit">Make the world a better place :)</button>
        </form>
      </div>
    </section>
  );
}
