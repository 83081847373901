import React from "react";
import { graphql } from "gatsby";

import HeroSection from "../sections/hero-feedback";
import PageLayout from "../components/page-layout";

export default function DiversityPage({ data, location }) {
  return (
    <PageLayout seo={{ title: "Diversa" }}>
      <HeroSection data={data} />
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "bg.svg" }) {
      publicURL
    }
  }
`;
