import React from "react";
import cn from "classnames";

import Star from "./star";

import * as style from "./star-rating.module.css";

export default function StarRating({
  value,
  onChange,
  containerClassName,
  starClassName,
}) {
  const [hoveredValue, setHoveredValue] = React.useState(0);

  const displayedValue = hoveredValue || value || 0;

  const stars = [];
  for (let i = 0; i < 5; i++) {
    const highlighted = Math.round(displayedValue) > i;

    stars.push(
      <span
        className={style.starContainer}
        key={i}
        onClick={() => {
          onChange(i + 1);
        }}
        onMouseEnter={
          onChange
            ? () => {
                setHoveredValue(i + 1);
              }
            : undefined
        }
        onMouseLeave={
          onChange
            ? () => {
                setHoveredValue(0);
              }
            : undefined
        }
      >
        <Star
          className={cn(style.star, starClassName, {
            [style.highlighted]: highlighted,
          })}
        />
      </span>
    );
  }

  return (
    <span className={cn(style.container, containerClassName)}>{stars}</span>
  );
}
