import React from "react";
import cn from "classnames";

import * as style from "./autocomplete-input.module.css";

export default function AutoCompleteInput({
  getSuggestions,
  options,
  value,
  onChange,
  ...otherProps
}) {
  options = options || {};
  options.minChars = options.minChars !== undefined ? options.minChars : 2;
  options.highlightPattern = options.highlightPattern || false;

  const suggestionContainerRef = React.useRef();
  const [suggestions, setSuggestions] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const [suggestionsActive, setSuggestionsActive] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(-1);

  function fillSuggestions(event) {
    setSuggestionsActive(true);
    setCurrentIndex(-1);

    let suggestions = [];
    if (event.target.value.length >= options.minChars) {
      suggestions = getSuggestions(event.target.value);

      console.log("SUGG:", event.target.value, suggestions);

      if (
        suggestions.length === 0 ||
        (suggestions.length === 1 && suggestions[0] === event.target.value)
      ) {
        setSuggestionsActive(false);
        return;
      }
    }

    setSuggestions(suggestions);
  }

  return (
    <div className={cn(style.inputContainer, { [style.active]: active })}>
      <input
        {...otherProps}
        autoComplete="off"
        type="text"
        value={value}
        onChange={(event) => {
          fillSuggestions(event);
          onChange(event.target.value);
        }}
        onFocus={(event) => {
          setActive(true);
          setSuggestionsActive(true);
          fillSuggestions(event);
        }}
        onBlur={(event) => {
          if (
            !event.relatedTarget ||
            event.relatedTarget.parentElement !== suggestionContainerRef.current
          ) {
            setActive(false);
            setSuggestionsActive(false);
          }
        }}
        onKeyDown={(event) => {
          let nextIndex = currentIndex;

          switch (event.keyCode) {
            case 38:
              event.preventDefault();
              nextIndex--;
              break;
            case 40:
              event.preventDefault();
              nextIndex++;
              break;
            case 13:
              event.preventDefault();
              if (nextIndex > -1) {
                setSuggestionsActive(false);
                onChange(suggestions[nextIndex]);
              }
              return;
          }

          setCurrentIndex(
            (nextIndex + suggestions.length) % suggestions.length
          );
        }}
      />
      <div
        className={cn(style.suggestionList, {
          [style.inactive]: !suggestionsActive,
        })}
        ref={suggestionContainerRef}
      >
        {suggestions.map((item, index) => (
          <div
            key={index}
            tabIndex="0"
            className={cn(item ? style.suggestion : style.vspace, {
              [style.active]: currentIndex === index,
            })}
            onClick={() => {
              setSuggestionsActive(false);
              onChange(item);
            }}
          >
            {options.highlightPattern ? (
              <React.Fragment>
                <b>{item.slice(0, value.length)}</b>
                {item.slice(value.length)}
              </React.Fragment>
            ) : (
              item
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
